import axios from "../../utils/axios";

//
export function groupJobList(params) {
    return axios({
        url: "/admin/groupJob/list",
        method: "get",
        params: params
    });
}

//
export function groupJobSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/groupJob/add"
            : "/admin/groupJob/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function groupJobDelete(data) {
    return axios({
        url: "/admin/groupJob/delete",
        method: "post",
        data: data
    });
}

//
export function groupJobSend(data) {
    return axios({
        url: "/admin/groupJob/send",
        method: "post",
        data: data
    });
}
